import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockBuilderComponent } from './block-builder.component';
import { TextBlockModule } from '../../blocks/text-block/text-block.module';
import { ImageBlockModule } from '../../blocks/image-block/image-block.module';
import { VideoBlockModule } from '../../blocks/video-block/video-block.module';
import { ContainerBlockModule } from '../../blocks/container-block/container-block.module';
import { HeroBlockModule } from '../../blocks/hero-block/hero-block.module';
import { FeaturedBlockModule } from '../../blocks/featured-block/featured-block.module';
import { TestimonialsBlockModule } from '../../blocks/testimonials-block/testimonials-block.module';
import { MapBlockModule } from '../../blocks/map-block/map-block.module';
import { ParallaxBlockModule } from '../../blocks/parallax-block/parallax-block.module';
import { ResultsBlockModule } from '../../blocks/results-block/results-block.module';
import { FormBlockModule } from '../../blocks/form-block/form-block.module';
import { ArticleBlockModule } from '../../blocks/article-block/article-block.module';
import { FaqSectionBlockModule } from '../../blocks/faq-section-block/faq-section-block.module';
import { MoreEntriesBlockModule } from '../../blocks/more-entries-block/more-entries-block.module';
import { ParallaxBackgroundBlockModule } from '../../blocks/parallax-background-block/parallax-background-block.module';
import { ProjectHeroBlockModule } from '../../blocks/project-hero-block/project-hero-block.module';
import { QuotesBlockModule } from '../../blocks/quotes-block/quotes-block.module';
import { PipedriveFormBlockModule } from '../../blocks/pipedrive-form-block/pipedrive-form-block.module';
import { HubspotFormBlockModule } from '../../blocks/hubspot-form-block/hubspot-form-block.module';

@NgModule({
  declarations: [BlockBuilderComponent],
  imports: [
    CommonModule,
    /* Blocks */
    TextBlockModule,
    ImageBlockModule,
    VideoBlockModule,
    ContainerBlockModule,
    HeroBlockModule,
    FeaturedBlockModule,
    TestimonialsBlockModule,
    MapBlockModule,
    ParallaxBlockModule,
    ResultsBlockModule,
    FormBlockModule,
    ArticleBlockModule,
    FaqSectionBlockModule,
    MoreEntriesBlockModule,
    ParallaxBackgroundBlockModule,
    ProjectHeroBlockModule,
    PipedriveFormBlockModule,
    HubspotFormBlockModule,
    QuotesBlockModule,
  ],
  exports: [BlockBuilderComponent],
})
export class BlockBuilderModule {}
