<div class="testimonial-items-container">
  <ngu-carousel #carousel [inputs]="carouselConfig" [dataSource]="testimonials">
    <ngu-tile *nguCarouselDef="let testimonial; let i = index">
      <div class="testimonial-item">
        <img
          *ngIf="!testimonial.link || !testimonial.link.href; else logoWithLink"
          class="logo"
          [src]="testimonial.logo | getAssetUrl"
        />

        <ng-template #logoWithLink>
          <app-dynamic-link [link]="testimonial.link">
            <img class="logo" [src]="testimonial.logo | getAssetUrl" />
          </app-dynamic-link>
        </ng-template>

        <blockquote>
          {{ testimonial.quote }}
        </blockquote>

        <cite *ngIf="testimonial.cite">
          <p class="author">
            {{ testimonial.cite }}
          </p>

          <p *ngIf="testimonial.citeAuthorRole" class="role">
            {{ testimonial.citeAuthorRole }}
          </p>
        </cite>
      </div>
    </ngu-tile>

    <button NguCarouselPrev class="previous" title="Previous testimonial">
      <img
        src="/assets/icons/arrow-black-to-right.svg"
        alt="Previous testimonial"
      />
    </button>

    <button NguCarouselNext class="next" title="Next testimonial">
      <img
        src="/assets/icons/arrow-black-to-right.svg"
        alt="Next testimonial"
      />
    </button>
  </ngu-carousel>
</div>
