<ng-container *ngIf="entries | async as entries">
  <div class="top-bar">
    <button
      class="previous"
      (click)="setCurrentIndex(-1)"
      title="Previous"
      [disabled]="currentIndex === 0"
    >
      <img src="/assets/icons/arrow-black-to-right.svg" alt="Previous item" />
    </button>

    <h2 class="heading">
      {{ block.heading }}
    </h2>

    <button
      class="next"
      (click)="setCurrentIndex(1)"
      title="Next"
      [disabled]="currentIndex + 4 >= entries.length"
    >
      <img src="/assets/icons/arrow-black-to-right.svg" alt="Next item" />
    </button>
  </div>
  <div class="items">
    <div
      class="item"
      *ngFor="let entry of entries | loadMorePagination: currentIndex"
      [ngStyle]="{
        backgroundImage:
          'url(' +
          (entry.thumbnail || entry.image | getAssetUrl: 'w=500&q=70') +
          ')'
      }"
    >
      <app-dynamic-link
        [link]="{ href: typeSlug + entry.slug }"
        class="flex-links center"
      >
        <h3 class="entry-heading">
          {{ entry.heading }}
        </h3>
      </app-dynamic-link>
    </div>
  </div>
</ng-container>
