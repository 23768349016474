import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MarkdownComponent } from 'ngx-markdown';

@Component({
  selector: 'app-faq-section-block',
  templateUrl: './faq-section-block.component.html',
  styleUrls: ['./faq-section-block.component.scss'],
  encapsulation: ViewEncapsulation.None, // For markdown
})
export class FaqSectionBlockComponent implements OnInit {
  @Input() block: FaqSectionBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  public openedIndexes: number[] = [];

  constructor() {}

  ngOnInit(): void {}

  public isOpened(i: number): boolean {
    return this.openedIndexes.includes(i);
  }

  public toggleBody(bodyElement: MarkdownComponent, i: number): void {
    const element = bodyElement.element.nativeElement;
    if (element.style.display === 'none') {
      this.openedIndexes.push(i);
      element.style.display = 'block';
      setTimeout(() => {
        element.style.opacity = '1';
      }, 50);
    } else {
      this.openedIndexes = this.openedIndexes.filter((index) => index !== i);
      element.style.display = 'none';
      element.style.opacity = '0';
    }
  }
}
