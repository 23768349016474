import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageBlockComponent } from './image-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [ImageBlockComponent],
  imports: [CommonModule, DynamicLinkModule, ContentfulHelpersModule],
  exports: [ImageBlockComponent],
})
export class ImageBlockModule {}
