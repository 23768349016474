import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loadMorePagination',
})
export class LoadMorePaginationPipe implements PipeTransform {
  transform(value: Article[], currentIndex: number): Article[] {
    return value.slice(currentIndex, currentIndex + 4);
  }
}
