import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsBlockComponent } from './results-block.component';

@NgModule({
  declarations: [ResultsBlockComponent],
  exports: [ResultsBlockComponent],
  imports: [CommonModule],
})
export class ResultsBlockModule {}
