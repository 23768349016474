<!-- TODO(joe): webp images are disabled due to broken images in safari https://trello.com/c/yXHhylX5/438-some-images-dont-work-on-safari -->
<picture
  class="lozad"
  style="display: block; min-height: 10px"
  [attr.data-iesrc]="image.desktop | getAssetUrl"
  [attr.data-alt]="image.alt || ''"
  [@fadeUp]="{ value: loaded ? '' : 'void', params: { time: 400, delay: 200 } }"
  #pictureElement
>
  <!-- <source
    [srcset]="
      image.desktop | getAssetUrl: 'auto':'webp':pictureElement:image.fit
    "
    media="(min-width: 800px)"
    type="image/webp"
  /> -->
  <source
    [srcset]="image.desktop | getAssetUrl: 'auto':'':pictureElement:image.fit"
    media="(min-width: 800px)"
  />
  <!-- Mobile images -->
  <!-- <source
    *ngIf="image.mobile"
    [srcset]="
      image.mobile | getAssetUrl: 'auto':'webp':pictureElement:image.fit
    "
    media="(max-width: 800px)"
    type="image/webp"
  /> -->
  <source
    *ngIf="image.mobile"
    [srcset]="image.mobile | getAssetUrl: 'auto':'':pictureElement:image.fit"
    media="(max-width: 800px)"
  />
  <!-- No mobile version available -->
  <!-- <source
    *ngIf="!image.mobile"
    [srcset]="
      image.desktop | getAssetUrl: 'auto':'webp':pictureElement:image.fit
    "
    media="(max-width: 800px)"
    type="image/webp"
  /> -->
  <source
    *ngIf="!image.mobile"
    [srcset]="image.desktop | getAssetUrl: 'auto':'':pictureElement:image.fit"
    media="(max-width: 800px)"
  />
</picture>
