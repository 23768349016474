import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectHeroBlockComponent } from './project-hero-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [ProjectHeroBlockComponent],
  exports: [ProjectHeroBlockComponent],
  imports: [
    CommonModule,
    ContentfulHelpersModule,
    DynamicLinkModule,
    NgxMarkdownModule.forChild(),
  ],
})
export class ProjectHeroBlockModule {}
