<div *ngIf="filters" class="filters-container">
  <a
    class="filter-button"
    [routerLink]="[]"
    routerLinkActive="filter-active"
    [queryParams]="{ filter: '' }"
  >
    All
  </a>
  <a
    *ngFor="let filter of filters"
    class="filter-button"
    [routerLink]="[]"
    routerLinkActive="filter-active"
    queryParamsHandling="merge"
    [queryParams]="{ filter: filter.value }"
  >
    {{ filter.title }}
  </a>
</div>

<div #gridElement class="grid" *ngIf="entries">
  <div class="grid-sizer"></div>
  <div
    *ngFor="let item of entries; trackbyFn"
    [class]="getClassName(item)"
    @fadeUp
    [ngClass]="{
      'grid-item-1x1': item.size === '1x1',
      'grid-item-2x1': item.size === '2x1',
      'grid-item-2x2': item.size === '2x2'
    }"
  >
    <app-dynamic-link
      *ngIf="item.link; else itemTemplate"
      [link]="item.link"
      class="only-content"
    >
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { item: item }"
      ></ng-container>
    </app-dynamic-link>

    <ng-template #itemTemplate>
      <img
        class="background-image"
        *ngIf="item.background"
        [src]="
          item.background | getAssetUrl: 'w=1000&fm=jpg&fl=progressive&q=70'
        "
        alt=""
      />

      <div class="grid-item-content">
        <p class="title" *ngIf="item.title || item.date">
          {{ item.title || (item.date | date: 'dd.MM.yyyy') }}
        </p>
        <h2 class="heading" *ngIf="item.heading">
          {{ item.heading }}
        </h2>
        <app-dynamic-link
          *ngIf="item.link"
          [link]="item.link"
          [class]="
            item.heading && item.size !== '1x1'
              ? 'button-white'
              : 'only-content'
          "
        >
          {{ item.link.text }}
        </app-dynamic-link>
      </div>
    </ng-template>
  </div>
</div>

<button
  *ngIf="loadMore && !loadMoreDisabled"
  class="load-more-button"
  (click)="load()"
>
  Load more
</button>
