import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Injector,
} from '@angular/core';
import { DynamicLinkComponent } from './dynamic-link.component';

@Directive({
  selector: '[appDynamicMarkdownLink]',
})
export class DynamicMarkdownLinkDirective {
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private element: ElementRef<HTMLElement>,
  ) {}

  @HostListener('ready')
  public processAnchors(element?: HTMLElement): void {
    const elementToProcess = element || this.element.nativeElement;
    /* Get all routerLinks generated by factory and change them to dynamic links */
    elementToProcess
      .querySelectorAll('a[dynamicLink]')
      .forEach((dynamicLinkElement) => {
        const component = this.componentFactoryResolver
          .resolveComponentFactory(DynamicLinkComponent)
          .create(this.injector, []);
        this.applicationRef.attachView(component.hostView);
        component.instance.link = {
          href: dynamicLinkElement.getAttribute('dynamicLink') || '',
          text: dynamicLinkElement.textContent || '',
        };
        dynamicLinkElement.replaceWith(component.location.nativeElement);
      });
  }
}
