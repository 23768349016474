import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-quotes-block',
  templateUrl: './quotes-block.component.html',
  styleUrls: ['./quotes-block.component.scss'],
  animations: [
    trigger('carouselMove', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(75vw)',
        }),
      ),
      state(
        'next',
        style({
          opacity: 0,
          transform: 'translateX(75vw)',
        }),
      ),
      state(
        'active',
        style({
          opacity: 1,
          transform: 'translateX(0vw)',
        }),
      ),
      state(
        'previous',
        style({
          opacity: 0,
          transform: 'translateX(-75vw)',
        }),
      ),
      transition('* => *', [animate('600ms cubic-bezier(0.65, 0, 0.35, 1)')]),
    ]),
  ],
})
export class QuotesBlockComponent implements OnInit, OnDestroy {
  @Input() block: QuotesBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  public items: QuoteItem[];
  public activeItem: QuoteItem;

  public height: string = '40vw';
  private subscriptions: Subscription[] = [];

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    const items = this.block.quotes;
    /* Map items to get indexes with them */
    this.items = items.map((item, index) => {
      return { ...item, index };
    });
    this.activeItem = this.items[0];
    this.setCurrentHeight();
    if (typeof window !== 'undefined') {
      const resizeSubscription = fromEvent(window, 'resize')
        .pipe(debounceTime(250))
        .subscribe(() => {
          this.setCurrentHeight();
        });
      this.subscriptions.push(resizeSubscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public setActiveItem(QuoteItem: QuoteItem): void {
    this.activeItem = QuoteItem;
  }

  public next(): void {
    const activeIndex = this.activeItem.index;
    const nextIndex = activeIndex + 1 < this.items.length ? activeIndex + 1 : 0;
    this.activeItem = this.items[nextIndex];
    this.setCurrentHeight();
  }

  public previous(): void {
    const activeIndex = this.activeItem.index;
    const previousIndex =
      activeIndex > 0 ? activeIndex - 1 : this.items.length - 1;
    this.activeItem = this.items[previousIndex];
    this.setCurrentHeight();
  }

  public getItemAnimationState(QuoteItem: QuoteItem): string {
    const activeIndex = this.activeItem.index;
    if (QuoteItem.index < activeIndex) {
      return 'previous';
    }
    if (QuoteItem.index === activeIndex) {
      return 'active';
    }
    return 'next';
  }

  private setCurrentHeight() {
    setTimeout(() => {
      const activeEl = this.el.nativeElement.querySelector('.active');
      if (activeEl) {
        this.height = activeEl.clientHeight + 'px';
      }
    }, 1);
  }
}
