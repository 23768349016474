import {
  AfterContentInit,
  AfterViewInit,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as lozad from 'lozad';
import vimeoPlayer from '@vimeo/player';
import { fadeUpAnimation } from '../../core/animations';

@Component({
  selector: 'app-video-block',
  templateUrl: './video-block.component.html',
  styleUrls: ['./video-block.component.scss'],
  animations: [fadeUpAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class VideoBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() block: VideoBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @ViewChild('vimeoPlayer') vimeoPlayerElement: ElementRef<HTMLDivElement>;

  public isVimeo: boolean;
  public playing: boolean;
  public started: boolean;
  public autoplay: boolean;
  public safeUrl: SafeResourceUrl;
  private player: vimeoPlayer;

  public loaded: boolean;
  private observer: IntersectionObserver;
  private imageObserver: lozad.Observer;

  constructor(
    private sanitizer: DomSanitizer,
    private element: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.autoplay = !!this.block.attributes && this.block.attributes.autoplay;
    if (this.block.videoLink) {
      if (this.block.videoLink.includes('vimeo')) {
        this.isVimeo = true;
      }
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.block.videoLink,
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.isVimeo) {
      this.player = new vimeoPlayer(this.vimeoPlayerElement.nativeElement, {
        url: this.block.videoLink,
        controls: false,
        autoplay: this.autoplay,
        loop: this.autoplay,
        muted: this.autoplay,
        background: this.autoplay,
      });
      if (!this.autoplay) {
        this.player.setVolume(1);
      }
    } else {
      this.imageObserver = lozad(this.getElement(), {
        rootMargin: Math.round(window.innerHeight * 0.75) + 'px',
      });
      this.imageObserver.observe();
    }

    const options = {
      threshold: 0.2,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.loaded = true;
      }
    }, options);
    this.observer.observe(this.getElement());
  }

  private getElement(): HTMLPictureElement {
    return this.element.nativeElement.querySelector('.video');
  }

  public togglePlay(): void {
    if (this.playing) {
      this.player.pause();
    } else {
      this.player.play();
    }
    this.playing = !this.playing;
    this.started = true;
  }

  ngOnDestroy(): void {
    try {
      this.observer.disconnect();
    } catch (error) {
      // Could not unobserve
    }
  }
}
