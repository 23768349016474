<ng-container *ngIf="!submitted; else submittedTemplate">
  <section
    class="form-body"
    markdown
    ngPreserveWhitespaces
    appDynamicMarkdownLink
  >
    {{ block.body }}
  </section>

  <app-dynamic-form
    [fields]="fields"
    (submitted)="submitted = true"
  ></app-dynamic-form>
</ng-container>

<ng-template #submittedTemplate>
  <section
    class="form-body"
    markdown
    ngPreserveWhitespaces
    appDynamicMarkdownLink
  >
    {{ block.afterSubmit || '# Submitted!' }}
  </section>
</ng-template>
