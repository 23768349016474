<ng-container *ngIf="block.videoFile; else iframeTemplate">
  <video
    class="video"
    disablePictureInPicture
    [attr.data-src]="block.videoFile | getAssetUrl"
    [attr.data-poster]="block.cover | getAssetUrl"
    [muted]="autoplay"
    [loop]="autoplay"
    [preload]="'auto'"
    [autoplay]="autoplay"
    [controls]="!autoplay"
    playsinline
    [@fadeUp]="{ value: loaded ? '' : 'void', params: { time: 500, delay: 0 } }"
  ></video>
</ng-container>

<ng-template #iframeTemplate>
  <div
    *ngIf="isVimeo"
    class="video vimeo-container"
    [ngClass]="{ autoplay: autoplay }"
    [@fadeUp]="{ value: loaded ? '' : 'void', params: { time: 500, delay: 0 } }"
  >
    <div
      *ngIf="block.cover && !started && !autoplay"
      class="cover"
      [ngStyle]="{
        backgroundImage: 'url(' + (block.cover | getAssetUrl) + ')'
      }"
    ></div>
    <button class="play-button" (click)="togglePlay()">
      <img src="/assets/icons/play.png" alt="Play video" *ngIf="!playing" />
    </button>
    <div class="player" #vimeoPlayer></div>
  </div>
  <iframe *ngIf="!isVimeo" [src]="safeUrl" frameborder="0"></iframe>
</ng-template>
