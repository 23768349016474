import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import * as lozad from 'lozad';
import { fadeUpAnimation } from '../../core/animations';

@Component({
  selector: 'app-dynamic-image',
  templateUrl: './dynamic-image.component.html',
  styleUrls: ['./dynamic-image.component.scss'],
  animations: [fadeUpAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicImageComponent implements AfterViewInit, OnDestroy {
  @Input() image: DynamicImageProps;

  @HostBinding('class.contain') get layoutRow(): boolean {
    return this.image.fit === 'contain';
  }

  private observer: lozad.Observer;
  public loaded: boolean;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.observer = lozad(this.getPicture(), {
      rootMargin: Math.round(window.innerHeight * 0.75) + 'px',
    });
    this.observer.observe();
    const options = {
      threshold: 0.2,
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.loaded = true;
      }
    }, options);

    observer.observe(this.getPicture());
  }

  private getPicture(): HTMLPictureElement {
    return this.element.nativeElement.querySelector('picture');
  }

  ngOnDestroy(): void {
    try {
      this.observer.observer.unobserve(this.getPicture());
    } catch (error) {
      // Could not unobserve
    }
  }
}
