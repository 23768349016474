import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBlockComponent } from './form-block.component';
import { DynamicFormModule } from '../../features/dynamic-form/dynamic-form.module';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [FormBlockComponent],
  exports: [FormBlockComponent],
  imports: [
    CommonModule,
    DynamicFormModule,
    ContentfulHelpersModule,
    NgxMarkdownModule,
    DynamicLinkModule,
  ],
})
export class FormBlockModule {}
