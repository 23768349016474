import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Masonry from 'masonry-layout';
import { first } from 'rxjs/operators';
import { getFields } from '../../core/contentful-helpers/get-fields.pipe';
import { ContentfulService } from '../../core/contentful.service';
@Component({
  selector: 'app-featured-block',
  templateUrl: './featured-block.component.html',
  styleUrls: ['./featured-block.component.scss'],
  animations: [
    trigger('fadeUp', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(20px)',
        }),
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        }),
      ),
      transition('* => *', [animate('300ms ease-out')]),
    ]),
  ],
})
export class FeaturedBlockComponent implements OnInit, AfterViewInit {
  @Input() block: FeaturedBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @ViewChild('gridElement') gridElement: ElementRef<HTMLDivElement>;

  public entries: FeaturedItem[];
  public loadMore: boolean;
  public filters: FeaturedBlockFilter[];
  public loadMoreDisabled: boolean;
  private currentIndex = 0;
  private loadAmount = 4;
  private msnry: Masonry;

  constructor(
    private contentfulService: ContentfulService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    const filterValue = this.getFilter();
    if (this.block.query || filterValue) {
      const type = this.block.query
        ? this.block.query.type || 'article'
        : 'article';
      switch (type) {
        case 'article':
          await this.getInitialArticles();
          break;

        default:
          await this.getInitialArticles();
          break;
      }
    } else {
      const entries = this.block.items.map((entry) => getFields(entry));
      this.entries = filterValue
        ? entries.filter(
            (entry) => !entry.category || entry.category.includes(filterValue),
          )
        : entries;

      this.initMsnry();
    }

    if (this.block.attributes) {
      const { loadMore, filters } = this.block.attributes;
      if (loadMore) {
        this.loadMore = true;
      }
      if (filters) {
        this.filters = filters;
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {}

  private initMsnry(): void {
    const scrollPostion = window.scrollY;
    setTimeout(() => {
      this.msnry = new Masonry(this.gridElement.nativeElement, {
        // use outer width of grid-sizer for columnWidth
        columnWidth: '.grid-sizer',
        // do not use .grid-sizer in layout
        itemSelector: '.grid-item',
        percentPosition: true,
      });
      window.scrollTo({ top: scrollPostion });
    }, 1);
  }

  public getClassName(item?: FeaturedItem): string {
    const fromContent =
      item.heading || item.hastag ? 'with-text' : 'image-only';
    const defaultClass = 'grid-item ' + fromContent;
    const classNames = item.classNames;
    if (!classNames) {
      return defaultClass;
    }
    return `${defaultClass} ${classNames.join(' ')}`;
  }

  private async getInitialArticles() {
    const articles = await this.contentfulService.getArticles();
    const entries = await articles.pipe(first()).toPromise();
    const filterValue = this.getFilter();

    this.entries = entries
      .filter(
        (entry) =>
          (!filterValue || entry.category.includes(filterValue)) &&
          !entry.hideFromArticlesPage,
      )
      .slice(this.currentIndex, this.currentIndex + this.loadAmount)
      .map((article) => this.buildArticleCard(article));
    this.initMsnry();
  }

  private buildArticleCard(article: Article): FeaturedItem {
    const item: FeaturedItem = {
      size: '2x2',
      date: article.date,
      title: null,
      link: {
        href: `/articles/${article.slug}`,
        type: 'internal',
        text: 'Read more',
      },
      background: article.image,
      heading: article.heading,
    };
    return item;
  }

  public async load(): Promise<void> {
    const filterValue = this.getFilter();
    const articlesObservable = this.contentfulService.getArticles();
    const articleEntries = await articlesObservable.pipe(first()).toPromise();
    const articleItems = articleEntries
      .filter(
        (article) =>
          !this.entries.find((entry) => entry.heading === article.heading) &&
          (!filterValue || article.category.includes(filterValue)) &&
          !article.hideFromArticlesPage,
      )
      .slice(0, this.loadAmount)
      .map((article) => this.buildArticleCard(article));
    this.entries = [...this.entries, ...articleItems];
    this.initMsnry();
    this.currentIndex = this.currentIndex + this.loadAmount;

    /* Check if all entries are visible */
    if (this.entries.length >= articleEntries.length) {
      this.loadMoreDisabled = true;
    }
  }

  trackbyFn(index): number {
    return index;
  }

  private getFilter(): string | null {
    return this.route.snapshot.queryParams.filter;
  }
}
