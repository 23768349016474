<article [ngClass]="{ 'content-only': !block.article.sideBlocks }">
  <div class="article-section article-section-content">
    <p class="date" *ngIf="block.article.date">
      {{ block.article.date | date: 'fullDate' }}
    </p>
    <section
      [innerHTML]="block.article.content | contentfulRichTextToHtml"
      section="article-content"
    ></section>
  </div>

  <section
    class="article-section article-section-side"
    *ngIf="block.article.sideBlocks as sideBlocks"
  >
    <app-container-block [block]="{ blocks: sideBlocks }"></app-container-block>
  </section>
</article>
