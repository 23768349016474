import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-parallax-block',
  templateUrl: './parallax-block.component.html',
  styleUrls: ['./parallax-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParallaxBlockComponent implements OnInit, AfterViewInit {
  @Input() block: ParallaxBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const options = {
      rootMargin: '0px',
      threshold: buildThresholdList(),
    };

    const observer = new IntersectionObserver(
      (e) => this.setCircleOpacity(e),
      options,
    );

    const targets = document.querySelectorAll('.parallax-section-content');
    targets.forEach((target) => {
      observer.observe(target);
    });
  }

  private setCircleOpacity(ieo: IntersectionObserverEntry[]): void {
    const blockElement = this.elementRef.nativeElement;

    ieo.forEach((ie) => {
      const id = ie.target.id;
      const sectionVisibleClass = `${id}-visible`;
      if (ie.intersectionRatio > 0.6) {
        blockElement.classList.add(sectionVisibleClass);
      } else if (
        ie.intersectionRatio < 0.5 &&
        blockElement.classList.contains(sectionVisibleClass)
      ) {
        blockElement.classList.remove(sectionVisibleClass);
      }
    });
  }
}

function buildThresholdList(): any[] {
  const thresholds = [];
  const numSteps = 20;

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}
