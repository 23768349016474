<section>
  <div markdown ngPreserveWhitespaces appDynamicMarkdownLink>
    {{ block.body }}
  </div>

  <div
    #wrapper
    class="pipedriveWebForms"
    attr.data-pd-webforms="https://webforms.pipedrive.com/f/{{
      block.pipedriveFormId
    }}"
  ></div>
</section>
