import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ContentfulService } from '../../core/contentful.service';

@Component({
  selector: 'app-more-entries-block',
  templateUrl: './more-entries-block.component.html',
  styleUrls: ['./more-entries-block.component.scss'],
})
export class MoreEntriesBlockComponent implements OnInit {
  @Input() block: MoreEntriesBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  public entries: Observable<Article[] | Project[]>;

  public typeSlug = '';

  public currentIndex = 0;

  constructor(
    private contentfulService: ContentfulService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loadEntries();
  }

  loadEntries(): void {
    if (this.block.query.type === 'article') {
      this.typeSlug = '/articles/';
      const currentPath = this.router.url.replace(/(\?|#)(.*)/g, ''); // Remove hashes and query params

      this.entries = this.contentfulService.getArticles().pipe(
        map((items) =>
          items.filter(
            (item) =>
              item.slug !== this.block.query.slug && // Legacy, no idea where query.slug comes from, scared to remove ¯\_(ツ)_/¯
              `${this.typeSlug}${item.slug}` !== currentPath && // New logic to check whether this entry is the active article page
              !item.hideFromArticlesPage,
          ),
        ),
      );
    }

    if (this.block.query.type === 'project') {
      this.typeSlug = '/projects/';
      this.entries = this.contentfulService
        .getProjects()
        .pipe(
          map((items) =>
            items.filter(
              (item) =>
                item.slug !== this.block.query.slug && !item.dateWithdrew,
            ),
          ),
        );
    }
  }

  setCurrentIndex(direction: number): void {
    if (direction < 0 && this.currentIndex - direction < 0) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = this.currentIndex + direction;
    }
  }
}
