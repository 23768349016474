import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-project-hero-block',
  templateUrl: './project-hero-block.component.html',
  styleUrls: ['./project-hero-block.component.scss'],
  encapsulation: ViewEncapsulation.None, // For markdown
})
export class ProjectHeroBlockComponent implements OnInit {
  @Input() block: ProjectHeroBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }
  constructor() {}

  ngOnInit(): void {}
}
