<h2>
  {{ block.heading }}
</h2>

<div class="sections">
  <section
    class="section"
    *ngFor="let section of block.sections; let i = index"
  >
    <div class="heading" (click)="toggleBody(bodyElement, i)">
      <button class="button">
        <img
          *ngIf="!isOpened(i)"
          src="/assets/icons/arrow-black-to-right.svg"
          alt="Open"
        />
        <img *ngIf="isOpened(i)" src="/assets/icons/x-black.svg" alt="Close" />
      </button>
      <h3 class="title">
        {{ section.title }}
      </h3>
    </div>
    <div
      class="body no-buttons"
      markdown
      ngPreserveWhitespaces
      appDynamicMarkdownLink
      #bodyElement
      [ngStyle]="{ display: 'none', opacity: 0 }"
    >
      {{ section.body }}
    </div>
  </section>
</div>
