import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  constructFields,
  Field,
} from '../../features/dynamic-form/dynamic-form.component';
import { contactForm } from './fields/contact';
import { newsletterFormFields } from './fields/newsletter';
import { whitePaperSignupFields } from './fields/whitePaperSignup';
import { FunctionsService } from '../../../../old/app/services/functions/functions.service';

@Component({
  selector: 'app-form-block',
  templateUrl: './form-block.component.html',
  styleUrls: ['./form-block.component.scss'],
})
export class FormBlockComponent implements OnInit {
  @Input() block: FormBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('id') get id(): string {
    return this.block.attributes && this.block.attributes.id;
  }

  public fields: Field[];
  public submitted: boolean;

  constructor(private fnService: FunctionsService) {}

  ngOnInit(): void {
    const dynamicFields = this.getDynamicFields(this.block.fields.type);
    this.fields = constructFields(dynamicFields, this.block);
  }

  private getDynamicFields(type: FieldTypes): Field[] {
    switch (type) {
      case 'contactForm':
        return contactForm(this.fnService, this.block.receiverEmail);
      case 'newsletter':
        return newsletterFormFields(this.fnService);
      case 'whitePaperEvent':
        return whitePaperSignupFields(this.fnService);

      default:
        return [];
    }
  }
}
