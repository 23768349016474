import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedBlockComponent } from './featured-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FeaturedBlockComponent],
  exports: [FeaturedBlockComponent],
  imports: [
    CommonModule,
    ContentfulHelpersModule,
    DynamicLinkModule,
    RouterModule,
  ],
})
export class FeaturedBlockModule {}
