import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appParallax]',
})
export class ParallaxDirective {
  @Input() parallaxRatio = 1;
  initialTop = 0;

  constructor(private eleRef: ElementRef<HTMLDivElement>) {
    this.initialTop = 0;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event): void {
    this.eleRef.nativeElement.style.backgroundPositionY =
      this.initialTop - window.scrollY * this.parallaxRatio + 'px';
  }
}
