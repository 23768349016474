import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleBlockComponent } from './article-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';
import { ContainerBlockModule } from '../container-block/container-block.module';

@NgModule({
  declarations: [ArticleBlockComponent],
  exports: [ArticleBlockComponent],
  imports: [
    CommonModule,
    ContentfulHelpersModule,
    DynamicLinkModule,
    ContainerBlockModule,
  ],
})
export class ArticleBlockModule {}
