import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParallaxBlockComponent } from './parallax-block.component';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [ParallaxBlockComponent],
  exports: [ParallaxBlockComponent],
  imports: [CommonModule, DynamicLinkModule, NgxMarkdownModule.forChild()],
})
export class ParallaxBlockModule {}
