import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoBlockComponent } from './video-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';

@NgModule({
  declarations: [VideoBlockComponent],
  imports: [CommonModule, ContentfulHelpersModule],
  exports: [VideoBlockComponent],
})
export class VideoBlockModule {}
