import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-pipedrive-form-block',
  templateUrl: './pipedrive-form-block.component.html',
  styleUrls: ['./pipedrive-form-block.component.scss'],
})
export class PipedriveFormBlockComponent implements AfterViewInit {
  @Input() block: PipedriveFormBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('id') get id(): string {
    return this.block.attributes && this.block.attributes.id;
  }

  @ViewChild('wrapper') formWrapper;

  ngAfterViewInit() {
    this.injectPipedriveLoader();
  }

  private injectPipedriveLoader(): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://webforms.pipedrive.com/f/loader';

    this.formWrapper.nativeElement.appendChild(script);
  }
}
