import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestimonialsBlockComponent } from './testimonials-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';
import { NguCarouselModule } from '@ngu/carousel';

@NgModule({
  declarations: [TestimonialsBlockComponent],
  exports: [TestimonialsBlockComponent],
  imports: [
    CommonModule,
    ContentfulHelpersModule,
    DynamicLinkModule,
    NguCarouselModule,
  ],
})
export class TestimonialsBlockModule {}
