<!-- Quotes carousel -->
<div
  *ngIf="height"
  class="carousel-items-container"
  [ngStyle]="{ height: height }"
>
  <div
    class="carousel-item"
    *ngFor="let item of items; let i = index"
    [class]="getItemAnimationState(item)"
    [@carouselMove]="getItemAnimationState(item)"
  >
    <!-- 
      <button class="previous" (click)="previous()" title="Previous">
       <img src="/assets/icons/arrow-black-to-right.svg" alt="Previous item" />
      </button>
   -->

    <div class="quote-content">
      <div class="quote-content-header">
        <p class="quotes-step">{{ i + 1 }}/{{ items.length }}</p>

        <button class="next" (click)="next()" title="Next">
          <img src="/assets/icons/arrow-black-to-right.svg" alt="Next item" />
        </button>
      </div>

      <blockquote class="carousel-quote">
        {{ item.quote }}
      </blockquote>

      <cite class="carousel-cite">
        {{ item.cite }}
      </cite>
    </div>
    <img class="quote-image" [src]="item.image" [alt]="item.cite" />
  </div>
</div>
