import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-block',
  templateUrl: './container-block.component.html',
  styleUrls: ['./container-block.component.scss'],
})
export class ContainerBlockComponent implements OnInit {
  @Input() block: ContainerBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('class.row') get layoutRow(): boolean {
    return this.block.layout === 'ROW';
  }
  @HostBinding('class.column') get layoutColumn(): boolean {
    return this.block.layout === 'COLUMN';
  }
  @HostBinding('class.grid') get layoutGrid(): boolean {
    return this.block.layout === 'GRID';
  }

  @HostBinding('id') get id(): string {
    if (this.block.attributes && this.block.attributes.id) {
      return this.block.attributes.id;
    }
    return this.block.contentId || '';
  }

  constructor() {}

  ngOnInit(): void {}
}
