import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoreEntriesBlockComponent } from './more-entries-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';
import { LoadMorePaginationPipe } from './load-more-pagination.pipe';

@NgModule({
  declarations: [MoreEntriesBlockComponent, LoadMorePaginationPipe],
  exports: [MoreEntriesBlockComponent],
  imports: [CommonModule, ContentfulHelpersModule, DynamicLinkModule],
})
export class MoreEntriesBlockModule {}
