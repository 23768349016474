import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { fadeUpOnlyAnimation } from '../../core/animations';

@Component({
  selector: 'app-results-block',
  templateUrl: './results-block.component.html',
  styleUrls: ['./results-block.component.scss'],
  animations: [fadeUpOnlyAnimation],
})
export class ResultsBlockComponent implements OnInit {
  @Input() block: ResultsBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }
  @HostBinding('style.backgroundImage') get backgroundImage(): string {
    return `url(${
      this.block.backgroundImage.fields.file.url
    }?q=70&fm=jpg&fl=progressive&w=${window.innerWidth + 500})`;
  }

  public activeReference: ResultsReference;

  constructor() {}

  ngOnInit(): void {
    this.activeReference = this.block.references.items[0];
  }

  public showResults(reference: ResultsReference): void {
    this.activeReference = reference;
  }
}
