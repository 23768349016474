import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

import { getFields } from '../../core/contentful-helpers/get-fields.pipe';

@Component({
  selector: 'app-testimonials-block',
  templateUrl: './testimonials-block.component.html',
  styleUrls: ['./testimonials-block.component.scss'],
})
export class TestimonialsBlockComponent implements OnInit {
  @Input() block: TestimonialsBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @ViewChild('carousel') carousel: NguCarousel<TestimonialItem>;

  public carouselConfig: NguCarouselConfig;
  public testimonials: TestimonialItem[];

  constructor() {}

  ngOnInit(): void {
    this.testimonials = getFields(this.block.items) as TestimonialItem[];

    this.carouselConfig = {
      loop: true,
      interval: { timing: 10000 },
      grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
      slide: 1,
      speed: 1000,
      point: {
        visible: true,
      },
      touch: true,
      easing: 'cubic-bezier(.3,.15,.13,1)',
    };
  }
}
