import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParallaxBackgroundBlockComponent } from './parallax-background-block.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { ParallaxDirective } from './parallax.directive';

@NgModule({
  declarations: [ParallaxBackgroundBlockComponent, ParallaxDirective],
  exports: [ParallaxBackgroundBlockComponent],
  imports: [CommonModule, ContentfulHelpersModule],
})
export class ParallaxBackgroundBlockModule {}
