import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBlockComponent } from './text-block.component';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [TextBlockComponent],
  imports: [CommonModule, DynamicLinkModule, NgxMarkdownModule.forChild()],
  exports: [TextBlockComponent],
})
export class TextBlockModule {}
