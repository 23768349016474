import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

function checkMatch(haystack: string, needle: RegExp): boolean {
  return haystack.match(needle) !== null ? true : false;
}

export enum UrlRegexIndexes {
  ASSET_REGEX = 'assetRegex',
  COMPENSATE_REGEX = 'compensateRegex',
}

export const URL_REGEXES: UrlRegexs = {
  [UrlRegexIndexes.ASSET_REGEX]: /^(https:|http:)?\/\/assets/,
  [UrlRegexIndexes.COMPENSATE_REGEX]: /^https?:\/\/(www\.)?compensate\.com/,
};

interface UrlRegexs {
  [key: string]: RegExp;
}

@Pipe({
  name: 'contentfulRichTextToHtml',
})
export class ContentfulRichTextToHtmlPipe implements PipeTransform {
  public options;

  constructor(private sanitizer: DomSanitizer) {
    const youtubeAllow =
      'allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"';
    this.options = {
      unsafe: false,
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) =>
          // TODO: find a way to use the picture component (app-picture (to lazy load with non native too))
          // TODO: handle pdf and other embeddable asset types
          `
          <picture>
            <source
              type="image/webp"
              media="(max-width: 320px)"
              srcset="${node.data.target.fields.file.url}?w=560&q=80&fm=webp"
            >
            <source
              type="image/webp"
              media="(max-width: 450px)"
              srcset="${node.data.target.fields.file.url}?w=820&q=80&fm=webp"
            >
            <source
              type="image/webp"
              media="(max-width: 640px)"
              srcset="${node.data.target.fields.file.url}?w=1200&q=80&fm=webp"
            >
            <source
              type="image/webp"
              srcset="${node.data.target.fields.file.url}?w=1600&q=80&fm=webp"
            >
            <img
              loading="lazy"
              src='${
                node.data.target.fields.file.url
              }?w=1600&q=80&fm=jpg&fl=progressive'
              alt='${node.data.target.fields.description || ''}'
            >
          </picture>
          `,
        [INLINES.ASSET_HYPERLINK]: (node) =>
          `<a
            href="https://${node.data.target.fields.file.url}"
            target="_blank"
            rel="noopener noreferrer"
          >
            ${node.content[0].value}
          </a>`,
        [INLINES.ENTRY_HYPERLINK]: (node) => {
          if (node.data.target.sys.contentType.sys.id === 'article') {
            const articleBaseUrl = `/articles/`;
            return `<a href="${articleBaseUrl}${node.data.target.fields.slug}">${node.content[0].value}</a>`;
          } else {
            return `<a href="${node.data.target.fields.slug}">${node.content[0].value}</a>`;
          }
        },
        [INLINES.HYPERLINK]: (node) => {
          if (node.data.uri.includes('player.vimeo.com/video')) {
            return `<span class="embed-video"><iframe src="${node.data.uri}" frameBorder="0" allow="autoplay;" allowFullScreen></iframe></span>`;
          } else if (node.data.uri.includes('youtube.com/embed')) {
            return `<span class="embed-video"><iframe src="${node.data.uri}" ${youtubeAllow} frameBorder="0" allowFullScreen></iframe></span>`;
          } else if (node.data.uri.includes('https://youtu.be/')) {
            return `<span class="embed-video"><iframe src="https://www.youtube.com/embed/${node.data.uri.replace(
              'https://youtu.be/',
              '',
            )}" ${youtubeAllow} frameBorder="0" allowFullScreen></iframe></span>`;
          }
          if (
            checkMatch(
              node.data.uri,
              URL_REGEXES[UrlRegexIndexes.ASSET_REGEX],
            ) ||
            !checkMatch(
              node.data.uri,
              URL_REGEXES[UrlRegexIndexes.COMPENSATE_REGEX],
            )
          ) {
            const externalString = ' target="_blank" rel="noopener noreferrer"';
            return `<a dynamicLink="${node.data.uri}"${externalString}>${node.content[0].value}</a>`;
          }
          return `<a dynamicLink="${node.data.uri}">${node.content[0].value}</a>`;
        },
      },
    };
  }

  transform(value: any): any {
    const html = documentToHtmlString(value, this.options);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
