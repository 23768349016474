<h2 class="heading">
  {{ block.heading }}
</h2>
<div
  class="map-container"
  (click)="toggleMap()"
  [ngClass]="{ showItems: showMap }"
>
  <section class="intro" markdown ngPreserveWhitespaces appDynamicMarkdownLink>
    {{ block.intro }}
  </section>

  <div #map class="map"></div>
</div>
