import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fadeUpAnimation } from '../../core/animations';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss'],
  animations: [fadeUpAnimation],
})
export class TextBlockComponent implements AfterViewInit, OnDestroy {
  @Input() block: TextBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('id') get id(): string {
    return this.block.attributes && this.block.attributes.id;
  }
  @HostBinding('style.background') get background(): string {
    return (
      this.block.attributes &&
      this.block.attributes.backgroundImage &&
      `center / cover no-repeat url("${this.block.attributes.backgroundImage}")`
    );
  }

  public loaded: boolean;
  private observer: IntersectionObserver;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    const options = {
      threshold: 0,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.loaded = true;
      }
    }, options);
    this.observer.observe(this.getElement());
  }

  private getElement(): HTMLPictureElement {
    return this.element.nativeElement.querySelector('section');
  }

  ngOnDestroy(): void {
    try {
      this.observer.disconnect();
    } catch (error) {
      // Could not unobserve
    }
  }
}
