<section
  class="hero-content-body"
  markdown
  ngPreserveWhitespaces
  appDynamicMarkdownLink
>
  {{ block.body }}
</section>

<div class="attributes" *ngIf="block.projectAttributes">
  <p class="item" *ngFor="let item of block.projectAttributes.items">
    {{ item.title }}
  </p>
</div>
