import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapBlockComponent } from './map-block.component';
import { RouterModule } from '@angular/router';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicLinkModule } from '../../features/dynamic-link/dynamic-link.module';

@NgModule({
  declarations: [MapBlockComponent],
  exports: [MapBlockComponent],
  imports: [
    CommonModule,
    RouterModule,
    DynamicLinkModule,
    NgxMarkdownModule.forChild(),
  ],
})
export class MapBlockModule {}
