import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotesBlockComponent } from './quotes-block.component';



@NgModule({
  declarations: [QuotesBlockComponent],
  imports: [
    CommonModule
  ],
  exports: [QuotesBlockComponent]
})
export class QuotesBlockModule { }
