import { Validators } from '@angular/forms';
import { MAILCHIMP_TAGS } from 'apps/website/src/environments/environment.common';
import { FunctionsService } from '../../../../../old/app/services/functions/functions.service';
import { Field } from '../../../features/dynamic-form/dynamic-form.component';

export const contactForm = (
  fnService: FunctionsService,
  receiverEmail: string,
): Field[] => [
  {
    key: 'firstName',
    placeholder: 'First name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'lastName',
    placeholder: 'Last name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'email',
    placeholder: 'Email',
    type: 'email',
    validators: [Validators.required],
  },
  {
    key: 'tel',
    placeholder: 'Phone',
    type: 'tel',
  },
  {
    key: 'company',
    placeholder: 'Company',
    type: 'text',
    className: 'field-full-width',
    validators: [Validators.required],
  },
  {
    key: 'message',
    placeholder: 'Message',
    type: 'textarea',
    className: 'field-full-width',
  },
  {
    key: 'approval',
    type: 'group',
    className: 'group-flex-row',
    fields: [
      {
        key: 'understand',
        label: 'Lorem ipsum',
        type: 'checkbox',
        validators: [Validators.requiredTrue],
      },
      {
        key: 'newsletter',
        label: 'Get newsletter',
        type: 'checkbox',
      },
      {
        key: 'submit',
        label: 'Send',
        type: 'submit',
        onSubmit: async ({
          email,
          firstName,
          lastName,
          phoneNumber,
          company,
          message,
          approval,
        }) => {
          try {
            await fnService.submitContactForm({
              email,
              firstName,
              lastName,
              phoneNumber,
              company,
              message,
              title: '',
              locale: 'en',
              receiverEmail,
            });
          } catch (error) {
            return {
              code: 'error',
              error: true,
              message: error?.message || 'Oops, something went wrong',
            };
          }

          if (approval.newsletter) {
            try {
              await fnService.addNewsletterSubscription({
                email,
                firstName,
                lastName,
                company,
                locale: 'en',
                tags: [MAILCHIMP_TAGS.B2B],
              });
            } catch {
              return {
                code: 'submitted',
                message:
                  "Your message was sent successfully but we couldn't add you to the newsletter. Sign up for our newsletter on the homepage",
              };
            }
          }

          return { code: 'submitted' };
        },
      },
    ],
  },
];
