import { AfterViewInit, Component, HostBinding, Input } from '@angular/core';

declare global {
  interface Window {
    hbspt: any;
  }
}

@Component({
  selector: 'app-hubspot-form-block',
  templateUrl: './hubspot-form-block.component.html',
  styleUrls: ['./hubspot-form-block.component.scss'],
})
export class HubspotFormBlockComponent implements AfterViewInit {
  @Input() block: HubspotFormBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('id') get id(): string {
    return this.block.attributes && this.block.attributes.id;
  }

  ngAfterViewInit() {
    this.injectHubspotLoader();
  }

  private injectHubspotLoader(tries = 0): void {
    if (typeof window.hbspt?.forms?.create === 'function') {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: '25697187',
        formId: this.block.hubspotFormId,
        target: '#hubspotFormWrapper',
      });
    } else if (tries < 10) {
      // The Hubspot script is added to the <head> and should already be loaded. But just
      // in case it's slow to load retry up to 10 times.
      setTimeout(() => this.injectHubspotLoader(tries + 1), 500);
    }
  }
}
