import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import * as lozad from 'lozad';
import { fadeUpAnimation } from '../../core/animations';

@Component({
  selector: 'app-image-block',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss'],
  animations: [fadeUpAnimation],
  encapsulation: ViewEncapsulation.None, // This for img element's styling to work
})
export class ImageBlockComponent implements AfterViewInit, OnDestroy {
  @Input() block: ImageBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('class.contain') get layoutRow(): boolean {
    return this.block.fit === 'contain';
  }

  public loaded: boolean;
  private observer: IntersectionObserver;
  private imageObserver: lozad.Observer;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.imageObserver = lozad(this.getPicture(), {
      rootMargin: Math.round(window.innerHeight * 0.75) + 'px',
    });
    this.imageObserver.observe();

    const options = {
      threshold: 0.2,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.loaded = true;
      }
    }, options);
    this.observer.observe(this.getPicture());
  }

  private getPicture(): HTMLPictureElement {
    return this.element.nativeElement.querySelector('picture');
  }

  ngOnDestroy(): void {
    try {
      this.imageObserver.observer.unobserve(this.getPicture());
      this.observer.disconnect();
    } catch (error) {
      // Could not unobserve
    }
  }
}
