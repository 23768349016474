import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetFieldsPipe } from './get-fields.pipe';
import { GetAssetUrlPipe } from './get-asset-url.pipe';
import { ContentfulRichTextToHtmlPipe } from './contentful-rich-text-to-html.pipe';

@NgModule({
  declarations: [GetFieldsPipe, GetAssetUrlPipe, ContentfulRichTextToHtmlPipe],
  exports: [GetFieldsPipe, GetAssetUrlPipe, ContentfulRichTextToHtmlPipe],
  imports: [CommonModule],
})
export class ContentfulHelpersModule {}
