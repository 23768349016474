<div class="svg-container">
  <svg
    class="compensate-c"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 179 185"
  >
    <defs>
      <style>
        .cls-1 {
          fill: #1b1b1b;
        }
      </style>
    </defs>
    <path
      class="cls-1 circle-1"
      d="M92.5,118.7A26.2,26.2,0,1,1,114.3,78l-6.9,4.6a17.9,17.9,0,1,0,0,19.8l6.9,4.6A26,26,0,0,1,92.5,118.7Z"
    />
    <path
      class="cls-1 circle-2"
      d="M132.7,119.3l-6.9-4.6a40,40,0,1,1,0-44.4l6.9-4.6a48.3,48.3,0,1,0,0,53.6Z"
    />
    <path
      class="cls-1 circle-3"
      d="M92.5,162.9A70.4,70.4,0,1,1,151,53.4L144.1,58a62.1,62.1,0,1,0,0,69l6.9,4.6A70.4,70.4,0,0,1,92.5,162.9Z"
    />
    <path
      class="cls-1 circle-4"
      d="M169.4,41.1a92.58,92.58,0,1,0-.1,102.8l-6.9-4.6a84.2,84.2,0,1,1,0-93.6Z"
    />
  </svg>
</div>
<div
  class="parallax-section"
  *ngFor="let section of block.sections; let i = index"
>
  <div class="parallax-section-content" [id]="'parallax-section-' + (i + 1)">
    <section
      class="parallax-section-body"
      markdown
      ngPreserveWhitespaces
      appDynamicMarkdownLink
    >
      {{ i + 1 }}

      {{ section.body }}
    </section>
  </div>
</div>
