<div
  class="hero-background-container"
  [ngClass]="{ 'has-mobile-image': block.mobileImage }"
>
  <ng-container *ngFor="let asset of block.media; let i = index">
    <!-- Desktop image -->
    <app-dynamic-image
      *ngIf="
        asset && asset.fields && asset.fields.file.contentType.includes('image')
      "
      class="hero-image hero-image-desktop"
      [image]="{ desktop: asset }"
    ></app-dynamic-image>

    <!-- Mobile image -->
    <img
      class="hero-image hero-image-mobile"
      *ngIf="block.mobileImage"
      [src]="block.mobileImage || asset | getAssetUrl"
    />
    <app-dynamic-image
      *ngIf="block.mobileImage"
      class="hero-image hero-image-mobile"
      [image]="{ desktop: asset }"
    ></app-dynamic-image>
    <!-- Video -->
    <video
      class="hero-video"
      disablePictureInPicture
      *ngIf="
        asset && asset.fields && asset.fields.file.contentType.includes('video')
      "
      [controls]="false"
      [muted]="true"
      [loop]="true"
      [preload]="'auto'"
      [autoplay]="true"
      playsinline
      [poster]="getPosterAsset(i) | getAssetUrl"
    >
      <source [src]="asset | getAssetUrl" type="video/mp4" />
    </video>
  </ng-container>
</div>

<section
  class="hero-content-body"
  markdown
  ngPreserveWhitespaces
  appDynamicMarkdownLink
  [@fadeUp]="{ value: '', params: { time: 800, delay: 500 } }"
>
  {{ block.body }}
</section>
