import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicMarkdownLinkDirective } from '../../features/dynamic-link/dynamic-markdown-link.directive';

@Component({
  selector: 'app-article-block',
  templateUrl: './article-block.component.html',
  styleUrls: ['./article-block.component.scss'],
  providers: [DynamicMarkdownLinkDirective],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleBlockComponent implements OnInit, AfterViewInit {
  @Input() block: ArticleBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  constructor(
    private dynamicLink: DynamicMarkdownLinkDirective,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dynamicLink.processAnchors(this.elementRef.nativeElement);
  }
}
