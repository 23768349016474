<!-- TODO(joe): webp images are disabled due to broken images in safari https://trello.com/c/yXHhylX5/438-some-images-dont-work-on-safari -->
<app-dynamic-link
  [link]="block.link"
  *ngIf="block.link && block.link.href; else imageTemplate"
>
  <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
</app-dynamic-link>

<ng-template #imageTemplate>
  <picture
    class="lozad"
    style="display: block; min-height: 10px"
    [attr.data-iesrc]="block.desktop | getAssetUrl"
    [attr.data-alt]="block.alt"
    [@fadeUp]="{ value: loaded ? '' : 'void', params: { time: 600 } }"
    #pictureElement
  >
    <!-- <source
      [srcset]="
        block.desktop | getAssetUrl: 'auto':'webp':pictureElement:block.fit
      "
      media="(min-width: 800px)"
      type="image/webp"
    /> -->
    <source
      [srcset]="block.desktop | getAssetUrl: 'auto':'':pictureElement:block.fit"
      media="(min-width: 800px)"
    />
    <!-- Mobile images -->
    <!-- <source
      *ngIf="block.mobile"
      [srcset]="
        block.mobile | getAssetUrl: 'auto':'webp':pictureElement:block.fit
      "
      media="(max-width: 800px)"
      type="image/webp"
    /> -->
    <source
      *ngIf="block.mobile"
      [srcset]="block.mobile | getAssetUrl: 'auto':'':pictureElement:block.fit"
      media="(max-width: 800px)"
    />
    <!-- No mobile version available -->
    <!-- <source
      *ngIf="!block.mobile"
      [srcset]="
        block.desktop | getAssetUrl: 'auto':'webp':pictureElement:block.fit
      "
      media="(max-width: 800px)"
      type="image/webp"
    /> -->
    <source
      *ngIf="!block.mobile"
      [srcset]="block.desktop | getAssetUrl: 'auto':'':pictureElement:block.fit"
      media="(max-width: 800px)"
    />
  </picture>
</ng-template>
