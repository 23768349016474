<div
  class="background--desktop"
  appParallax
  [parallaxRatio]="-0.42"
  [ngStyle]="{
    backgroundImage: 'url(' + (block.background | getAssetUrl) + ')'
  }"
></div>
<div
  class="background--mobile"
  [ngStyle]="{
    backgroundImage: 'url(' + (block.background | getAssetUrl) + ')'
  }"
></div>
