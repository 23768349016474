import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerBlockComponent } from './container-block.component';
import { ImageBlockModule } from '../image-block/image-block.module';
import { TextBlockModule } from '../text-block/text-block.module';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { VideoBlockModule } from '../video-block/video-block.module';
import { FaqSectionBlockModule } from '../faq-section-block/faq-section-block.module';
import { PipedriveFormBlockModule } from '../pipedrive-form-block/pipedrive-form-block.module';
import { HubspotFormBlockModule } from '../hubspot-form-block/hubspot-form-block.module';

@NgModule({
  declarations: [ContainerBlockComponent],
  imports: [
    CommonModule,
    ContentfulHelpersModule,
    /* Blocks */
    TextBlockModule,
    ImageBlockModule,
    VideoBlockModule,
    FaqSectionBlockModule,
    PipedriveFormBlockModule,
    HubspotFormBlockModule,
  ],
  exports: [ContainerBlockComponent],
})
export class ContainerBlockModule {}
