<ng-container *ngIf="blocks">
  <ng-container *ngFor="let block of blocks; let i = index">
    <ng-container [ngSwitch]="block.contentType">
      <!-- Text block -->
      <app-text-block
        *ngSwitchCase="'textBlock'"
        [block]="block"
      ></app-text-block>

      <!-- Image block -->
      <app-image-block
        *ngSwitchCase="'imageBlock'"
        [block]="block"
      ></app-image-block>

      <!-- Video block -->
      <app-video-block
        *ngSwitchCase="'videoBlock'"
        [block]="block"
      ></app-video-block>

      <!-- Container block -->
      <app-container-block
        *ngSwitchCase="'containerBlock'"
        [block]="block"
      ></app-container-block>

      <!-- Hero block -->
      <app-hero-block
        *ngSwitchCase="'heroBlock'"
        [block]="block"
      ></app-hero-block>

      <!-- Featured block -->
      <app-featured-block
        *ngSwitchCase="'featuredBlock'"
        [block]="block"
      ></app-featured-block>

      <!-- Testimonials block -->
      <!-- carouselBlock is the legacy content type ID, the "Testimonials Block" is now used purely for testimonials -->
      <app-testimonials-block
        *ngSwitchCase="'carouselBlock'"
        [block]="block"
      ></app-testimonials-block>

      <!-- Map block -->
      <app-map-block *ngSwitchCase="'mapBlock'" [block]="block"></app-map-block>

      <!-- Parallax block -->
      <app-parallax-block
        *ngSwitchCase="'parallaxBlock'"
        [block]="block"
      ></app-parallax-block>

      <!-- Results block -->
      <app-results-block
        *ngSwitchCase="'resultsBlock'"
        [block]="block"
      ></app-results-block>

      <!-- Form block -->
      <app-form-block
        *ngSwitchCase="'formBlock'"
        [block]="block"
      ></app-form-block>

      <!-- Pipedrive Form block -->
      <app-pipedrive-form-block
        *ngSwitchCase="'pipedriveForm'"
        [block]="block"
      ></app-pipedrive-form-block>

      <!-- Hubspot Form block -->
      <app-hubspot-form-block
        *ngSwitchCase="'hubspotFormBlock'"
        [block]="block"
      ></app-hubspot-form-block>

      <!-- Article block -->
      <app-article-block
        *ngSwitchCase="'articleBlock'"
        [block]="block"
      ></app-article-block>

      <!-- Faq section block -->
      <app-faq-section-block
        *ngSwitchCase="'faqSectionBlock'"
        [block]="block"
      ></app-faq-section-block>

      <!-- More entries block -->
      <app-more-entries-block
        *ngSwitchCase="'moreEntriesBlock'"
        [block]="block"
      ></app-more-entries-block>

      <!-- Parallax background block -->
      <app-parallax-background-block
        *ngSwitchCase="'parallaxBackgroundBlock'"
        [block]="block"
      ></app-parallax-background-block>

      <!-- Project hero block -->
      <app-project-hero-block
        *ngSwitchCase="'projectHeroBlock'"
        [block]="block"
      ></app-project-hero-block>

      <!-- Quotes block -->
      <app-quotes-block
        *ngSwitchCase="'quotesBlock'"
        [block]="block"
      ></app-quotes-block>

      <div *ngSwitchDefault [attr.missing-type]="block.contentType"></div>
    </ng-container>
  </ng-container>
</ng-container>
