<article class="results-content">
  <h2 class="heading">
    {{ block.heading }}
  </h2>
  <p class="ingress">
    {{ block.ingress }}
  </p>
  <p class="body">
    {{ block.body }}
  </p>

  <img class="arrow" src="/assets/icons/results-arrow.png" alt="Scroll down" />

  <div class="references">
    <p class="references-choose">
      Choose reference:
      <img
        src="/assets/icons/arrow-white-to-right.svg"
        alt="More on the right"
      />
    </p>
    <div class="reference-buttons-container">
      <button
        class="reference-button"
        *ngFor="let reference of block.references.items"
        [ngClass]="{ active: reference.title === activeReference.title }"
        (click)="showResults(reference)"
      >
        {{ reference.title }}
      </button>
    </div>
  </div>

  <div class="results">
    <div
      class="result"
      *ngFor="let result of activeReference.results"
      [ngClass]="{ faded: result.class && result.class.includes('faded') }"
    >
      <p class="result-amount">
        {{ result.amount }}
      </p>
      <p class="result-unit">
        {{ result.unit }}
      </p>
      <p class="result-description">
        {{ result.description }}
      </p>
    </div>
  </div>
</article>
