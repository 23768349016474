import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicImageComponent } from './dynamic-image.component';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';

@NgModule({
  declarations: [DynamicImageComponent],
  imports: [CommonModule, ContentfulHelpersModule],
  exports: [DynamicImageComponent],
})
export class DynamicImageModule {}
