import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { link } from 'fs';
import { Map, Marker } from 'mapbox-gl';
import { getFields } from '../../core/contentful-helpers/get-fields.pipe';
import {
  scrollToElement,
  silentlyNavigate,
} from '../../features/dynamic-link/dynamic-link.component';
@Component({
  selector: 'app-map-block',
  templateUrl: './map-block.component.html',
  styleUrls: ['./map-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapBlockComponent implements OnInit, AfterViewInit {
  @Input() block: MapBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  @HostBinding('id') get id(): string {
    if (this.block.attributes && this.block.attributes.id) {
      return this.block.attributes.id;
    }
    return this.block.contentId || '';
  }

  private map: Map;

  @ViewChild('map') mapElement: ElementRef<HTMLDivElement>;

  public showMap: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.map === 'open'
    ) {
      this.showMap = true;
    }

    const head = document.getElementsByTagName('head')[0];
    const existing = document.getElementById('map-css');
    if (!existing) {
      const linkElement: HTMLLinkElement = document.createElement('link');
      linkElement.id = 'map-css';
      linkElement.rel = 'stylesheet';
      linkElement.href =
        'https://api.mapbox.com/mapbox-gl-js/v2.0.1/mapbox-gl.css';
      head.appendChild(linkElement);
    }
  }

  ngAfterViewInit(): void {
    this.map = new Map({
      accessToken:
        'pk.eyJ1IjoidGVlbXVsYWhqYWxhaHRpYm91IiwiYSI6ImNraDh5YnkyMTA3Z3EydnM3N3Ftamw1M2MifQ.LbS827LusS5wvgObVamx4g',
      container: this.mapElement.nativeElement,
      style: 'mapbox://styles/teemulahjalahtibou/ckh8ykwsy1hz319rys6bkbqot',
      center: { lat: 0, lng: 100 },
      zoom: 1.66,
      minZoom: 0,
      maxZoom: 6,
      scrollZoom: false,
      interactive: true,
    });

    this.map.fitBounds([
      [80, 85],
      [-80, -57.5],
    ]);

    this.map.once('click', () => this.toggleMap());
    this.map.once('dragstart', () => this.toggleMap());

    this.block.steps
      .map((item) => getFields(item))
      .forEach((item) => {
        const el = this.constructMarkerElement(item);
        new Marker(el, { anchor: this.getAnchor(item) })
          .setLngLat(item.location)
          .addTo(this.map);
      });

    /* This can't be done in ngOnInit */
    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.map === 'open'
    ) {
      scrollToElement(this.block.attributes.id);
    }
  }

  private getAnchor(
    item: MapItem,
  ):
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right' {
    if (!item.classNames) {
      return 'bottom-left';
    }
    if (item.classNames.includes('to-right')) {
      return 'left';
    }
    if (item.classNames.includes('to-left')) {
      return 'right';
    }
    if (item.classNames.includes('to-bottom')) {
      return 'top-left';
    }
    return 'bottom-left';
  }

  private constructMarkerElement(item: MapItem): HTMLElement {
    const unavailable = item.appProjectPage.fields.temporarilyUnavailable;
    const markerContainer = document.createElement('div');
    const markerDot = document.createElement('div');
    const markerLine = document.createElement('div');
    const markerText = document.createElement('h3');

    markerContainer.className = `marker-container ${
      unavailable ? ' marker-container__unavailable ' : ''
    } ${item.classNames ? item.classNames.join(' ') : ''}`;

    markerText.className = 'marker-text';
    markerLine.className = 'marker-line';
    markerDot.className = 'marker-dot';

    markerText.innerHTML = `<b>${item.title}</b><br/>${
      unavailable ? '<em>Currently Unavailable</em>' : item.heading
    }`;

    markerContainer.appendChild(markerText);
    markerContainer.appendChild(markerLine);
    markerContainer.appendChild(markerDot);

    markerContainer.addEventListener('click', () => {
      this.router.navigate(['projects', item.appProjectPage.fields.slug]);
    });

    return markerContainer;
  }

  public toggleMap(): void {
    this.showMap = true;
    const url = new URL(window.location.href);
    if (url.searchParams.get('map') !== 'open') {
      url.searchParams.append('map', 'open');
    }
    silentlyNavigate(url.href);
  }
}
