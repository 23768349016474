import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-background-block',
  templateUrl: './parallax-background-block.component.html',
  styleUrls: ['./parallax-background-block.component.scss'],
})
export class ParallaxBackgroundBlockComponent implements OnInit {
  @Input() block: ParallaxBackgroundBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }
  constructor() {}

  ngOnInit(): void {}
}
