import { Validators } from '@angular/forms';
import { MAILCHIMP_TAGS } from 'apps/website/src/environments/environment.common';
import { FunctionsService } from '../../../../../old/app/services/functions/functions.service';
import { Field } from '../../../features/dynamic-form/dynamic-form.component';

export const whitePaperSignupFields = (
  fnService: FunctionsService,
): Field[] => [
  {
    key: 'firstName',
    placeholder: 'First name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'lastName',
    placeholder: 'Last name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'email',
    placeholder: 'Email',
    type: 'email',
    className: 'field-full-width',
    validators: [Validators.required],
  },
  {
    key: 'approval',
    type: 'group',
    className: 'group-flex-row',
    fields: [
      {
        key: 'understand',
        label: 'I accept privacy policy',
        type: 'checkbox',
        className: 'text-align-reset',
        validators: [Validators.requiredTrue],
      },
      {
        key: 'submit',
        label: 'Send',
        type: 'submit',
        onSubmit: async ({ email, firstName, lastName }) => {
          try {
            await fnService.addNewsletterSubscription({
              email,
              firstName,
              lastName,
              locale: 'en',
              tags: [MAILCHIMP_TAGS.WHITEPAPER, MAILCHIMP_TAGS.B2B],
            });

            return { code: 'submitted' };
          } catch (error) {
            return {
              code: 'error',
              error: true,
              message: error?.message || 'Oops, something went wrong',
            };
          }
        },
      },
    ],
  },
];
