<ng-container *ngIf="block.blocks">
  <ng-container *ngFor="let block of block.blocks | getFields; let i = index">
    <ng-container [ngSwitch]="block.contentType">
      <!-- Text block -->
      <app-text-block
        *ngSwitchCase="'textBlock'"
        [block]="block"
      ></app-text-block>

      <!-- Image block -->
      <app-image-block
        *ngSwitchCase="'imageBlock'"
        [block]="block"
      ></app-image-block>

      <!-- Video block -->
      <app-video-block
        *ngSwitchCase="'videoBlock'"
        [block]="block"
      ></app-video-block>

      <!-- Container block -->
      <app-container-block
        *ngSwitchCase="'containerBlock'"
        [block]="block"
      ></app-container-block>

      <!-- Faq section block -->
      <app-faq-section-block
        *ngSwitchCase="'faqSectionBlock'"
        [block]="block"
      ></app-faq-section-block>

      <!-- Pipedrive Form block -->
      <app-pipedrive-form-block
        *ngSwitchCase="'pipedriveForm'"
        [block]="block"
      ></app-pipedrive-form-block>

      <!-- Hubspot Form block -->
      <app-hubspot-form-block
        *ngSwitchCase="'hubspotFormBlock'"
        [block]="block"
      ></app-hubspot-form-block>

      <div *ngSwitchDefault>{{ block.contentType }} - Block not found</div>
    </ng-container>
  </ng-container>
</ng-container>
