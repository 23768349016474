import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { fadeUpAnimation } from '../../core/animations';
@Component({
  selector: 'app-hero-block',
  templateUrl: './hero-block.component.html',
  styleUrls: ['./hero-block.component.scss'],
  encapsulation: ViewEncapsulation.None, // For markdown
  animations: [fadeUpAnimation],
})
export class HeroBlockComponent implements OnInit {
  @Input() block: HeroBlockProps;

  @HostBinding('class') get classNames(): string {
    return this.block.classNames && this.block.classNames.join(' ');
  }

  constructor() {}

  ngOnInit(): void {}

  getPosterAsset(i: number): Asset {
    if (!this.block.placeholdersForVideos) {
      return null;
    }
    if (this.block.placeholdersForVideos[i]) {
      return this.block.placeholdersForVideos[i];
    }
    return this.block.placeholdersForVideos[0];
  }
}
